import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import bg1 from '../assets/bg1.jpg';
import bg2 from '../assets/bg2.jpg';
import bg3 from '../assets/bg3.jpg';
import bg4 from '../assets/bg4.jpg';
import kids from '../assets/kidshaircut.jpg';
import mens from '../assets/menshaircut.jpg';
import womens from '../assets/womenshaircut.jpg';
import beard from '../assets/beardtrim.jpg';
import design from '../assets/design.jpg';
import shampoo from '../assets/shampoo.jpg';
import buzzcut from '../assets/buzzcuthaircut.jpg';
import senior from '../assets/seniorhaircut.jpg';
import fade from '../assets/bg4.jpg';

const Services = () => {
  const images = [bg1, bg2, bg3, bg4];
  const bgNum = Math.floor(Math.random() * 4);
  const bgImage = images[bgNum];

  return (
    <div className="text-center">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center flex-col">
          <h1 className="text-white text-4xl font-bold mb-2">KUWENTO</h1>
          <div className="text-white text-xl font-bold px-2">Call us at (425) 746-5747 to book</div>
        </div>
      </motion.div>
      <div className="max-w-4xl mx-auto text-white px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <ServiceCard title="Men's Haircut" price="$35" backgroundImage={mens} />
          <ServiceCard title="Kid's Haircut" price="$30" backgroundImage={kids} />
          <ServiceCard title="Women's Haircut" price="$40" backgroundImage={womens} />
          <ServiceCard title="Haircut & Shampoo" price="$40" backgroundImage={shampoo} />
          <ServiceCard title="Beard Trim & Shave" price="$20" backgroundImage={beard} />
          <ServiceCard title="Skin Fade" price="$40" backgroundImage={fade} />
          <ServiceCard title="Design" price="$45" backgroundImage={design} />
          <ServiceCard title="Buzz Cut" price="$20" backgroundImage={buzzcut} />
          <ServiceCard title="Senior Haircut" price="$30" backgroundImage={senior} />
          <ServiceCard title="Tapered Fade" price="$40" backgroundImage={fade} />
          

        </div>
      </div>
    </div>
  );
};

const ServiceCard = React.forwardRef(({ title, price, backgroundImage }, ref) => {
  const { ref: inViewRef, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const cardStyle = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <Link to="/contact" className="block" ref={inViewRef}>
      <motion.div
        className="bg-white bg-opacity-25 rounded-md p-4 flex flex-col items-center justify-center text-center hover:bg-opacity-50 transition duration-300"
        style={cardStyle}
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.5 }}
        whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",transition: { duration: 0.2 }
         }}
      >
        <h2 className="text-xl font-semibold mb-2">{title}</h2>
        <p className="text-lg">{price}</p>
      </motion.div>
    </Link>
  );
});

export default Services;
