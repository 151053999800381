import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import bg1 from '../assets/bg1.jpg';
import bg2 from '../assets/bg2.jpg';
import bg3 from '../assets/bg3.jpg';
import bg4 from '../assets/bg4.jpg';
import banner1 from '../assets/banner1.png';
import banner2 from '../assets/banner2.png';
import img1 from '../assets/img1.png';

const Home = () => {
  const images = [bg1, bg2, bg3, bg4];
  const bgNum = Math.floor(Math.random() * 4);
  const bgImage = images[bgNum];

  const banners = [banner1, banner2];
  const bannerNum = Math.floor(Math.random() * 1);
  const bannerImage = banners[bannerNum];

  return (
    <div className="text-center">
      <motion.div
        className="relative h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${bannerImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center flex-col">
          <h1 className="text-white text-6xl font-bold" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}>
            KUWENTO
          </h1>
          <br />
          <h3 className="text-white text-2xl font-bold px-2" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}>
            Formally Larry's Barbershop.
          </h3>
        </div>
      </motion.div>
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center flex-col">
          <h3 className="text-white text-3xl font-bold" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}>
            Established in 1964. Over half a century of customer satisfaction.
          </h3>
          <div className="mt-8 space-x-4 flex flex-wrap justify-center">
            <motion.div
              whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)", transition: { duration: 0.2 } }}
              className="inline-block mb-4"
            >
              <Link to="/services" className="bg-gray-800 text-white px-4 py-2 rounded font-bold">
                ↗️ View Services
              </Link>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)", transition: { duration: 0.2 } }}
              className="inline-block mb-4"
            >
              <Link to="/contact" className="bg-gray-800 text-white px-4 py-2 rounded font-bold">
                📞 Contact Us
              </Link>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)", transition: { duration: 0.2 } }}
              className="inline-block mb-4"
            >
              <a href="https://www.yelp.com" target="_blank" rel="noopener noreferrer" className="bg-gray-800 text-white px-4 py-2 rounded font-bold">
                🗓️ Book Us on Yelp
              </a>
            </motion.div>
          </div>
        </div>
      </motion.div>
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold mb-4">Welcome to KUWENTO</h2>
        <p className="text-xl text-gray-700 mb-8">Your story begins here...</p>
        <div className="flex flex-col md:flex-row items-start justify-center">
          <img src={img1} alt="Gallery 1" className="w-full md:w-1/2 h-64 object-cover mb-4 md:mb-0" />
          <div className="md:ml-4 text-left md:self-start">
            <h3 className="text-xl font-bold">Former Seahawks Running Back</h3>
            <p className="text-gray-700">Pictured here is former Seahawks Running Back Thomas Rawls getting a high quality haircut. Book yours today by calling (425) 746-5747.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
