import React from 'react';
import { FaPhoneAlt, FaMapMarkerAlt, FaYelp } from 'react-icons/fa';
import { motion } from 'framer-motion';

const Contact = () => {
  return (
    <div className="text-center">
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
        <div className="flex flex-col items-center space-y-4 mb-8">
          <div className="flex items-center space-x-2">
            <FaPhoneAlt className="text-blue-500" style={{ verticalAlign: 'middle' }} />
            <p className="text-lg">Call us at (425) 746-5747 to book</p>
          </div>
          <div className="flex items-center space-x-2">
            <FaMapMarkerAlt className="text-red-500" style={{ verticalAlign: 'middle' }} />
            <p className="text-lg">1504 145th Pl SE, Bellevue, WA 98007</p>
          </div>
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{ scale: 1.1, transition: { duration: 0.3 } }}
          >
            <FaYelp className="text-red-500" style={{ verticalAlign: 'middle' }} />
            <a
              href="https://www.yelp.com"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-gray-800 text-white px-4 py-2 rounded font-bold flex items-center space-x-2"
              style={{ perspective: '500px' }}
            >
              Book us on Yelp
            </a>
          </motion.div>
          <div className="text-2xl font-bold underline underline-offset-1">Our Hours</div>
          <div className="text-lg">Open Monday 9:00am - 5:00pm</div>
          <div className="text-lg">Open Tuesday- Saturday 9:00am - 6.00pm</div>
          <div className="text-lg">Sunday- appointment only</div>
        </div>
        <div className="w-full px-4">
          <iframe
            title="Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2690.5054466197175!2d-122.15003169999999!3d47.5968607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906c3c894b9fcb%3A0xadbe0938088d5887!2sLarry%E2%80%99s%20Barber%20shop!5e0!3m2!1sen!2sus!4v1717904497890!5m2!1sen!2sus"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
