import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from '../assets/logo.png'; // Adjust the path according to where your logo is stored

const Header = () => {
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <header className="bg-gray-900 text-white">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <img src={logo} alt="KUWENTO Logo" className="h-8 w-8 mr-2" />
          <span className="text-2xl font-bold">KUWENTO</span>
        </Link>
        <nav className="hidden md:flex space-x-4">
          <Link to="/" className={`hover:underline ${location.pathname === '/' && 'underline'}`}>Home</Link>
          <Link to="/services" className={`hover:underline ${location.pathname === '/services' && 'underline'}`}>Services</Link>
          <Link to="/gallery" className={`hover:underline ${location.pathname === '/gallery' && 'underline'}`}>Our Shop</Link>
          <Link to="/contact" className={`hover:underline ${location.pathname === '/contact' && 'underline'}`}>Contact</Link>
        </nav>
        <button className="md:hidden" onClick={toggleDrawer}>
          <FaBars />
        </button>
      </div>
      {drawerOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 z-50 md:hidden">
          <div className="absolute top-0 right-0 p-4">
            <button onClick={toggleDrawer}>
              <FaTimes className="text-white" />
            </button>
          </div>
          <div className="flex flex-col items-center justify-center h-full space-y-4">
            <Link to="/" onClick={toggleDrawer} className={`text-white text-xl ${location.pathname === '/' && 'underline'}`}>Home</Link>
            <Link to="/services" onClick={toggleDrawer} className={`text-white text-xl ${location.pathname === '/services' && 'underline'}`}>Services</Link>
            <Link to="/gallery" onClick={toggleDrawer} className={`text-white text-xl ${location.pathname === '/gallery' && 'underline'}`}>Our Shop</Link>
            <Link to="/contact" onClick={toggleDrawer} className={`text-white text-xl ${location.pathname === '/contact' && 'underline'}`}>Contact</Link>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
